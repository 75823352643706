<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
            </div>
            <div class="pleft">当前位置：<span>首页</span><span>>机票预订</span></div>
        </div>
        <div v-for="(item, index) in xcForm.xcList" :key="item.flightno">
            <div class="pginfo flex">
                <div class="xuhao">
                    {{ index + 1 }}
                </div>
                <div class="pginfotitle">行程信息</div>
                <div class="pgdel" @click="removexc(index)">【删除】</div>
            </div>
            <div class="hcxc">
                <el-form size="small" :inline="true" ref="form" :model="form" label-width="80px" class="formstyleyd">
                    <el-col :span="8">
                        <el-form-item label="出发日期" prop="sendate">
                            <el-date-picker type="datetime" class="inputstyle" placeholder="选择日期" format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm" v-model="item.senddate"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <!-- <el-form-item label="到达日期" class="" prop="sendate">
                        <el-col :span="20">
                            <el-date-picker type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm" v-model="item.arrivaldate"
                                style="width: 10vw"></el-date-picker>
                        </el-col>
                    </el-form-item> -->
                    <el-col :span="8">
                        <el-form-item label="出发" class=" fsizestyle" prop="sendcity">
                            <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="item.sendcity"
                                clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="到达" prop="arrivalcity">
                            <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="item.arrivalcity"
                                clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="航班" class="fsizestyle" prop="flightno">
                            <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="item.flightNumber"
                                clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="舱位" class="" prop="cabin">
                            <el-select class="inputstyle" v-model="item.cabintype" placeholder="选择席座" @change="cabintypeChange">
                                <el-option label="经济舱" value="经济舱"></el-option>
                                <el-option label="头等舱" value="头等舱"></el-option>
                                <el-option label="公务舱" value="公务舱"></el-option>
                                <el-option label="商务舱" value="商务舱"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form>
            </div>
        </div>

        <div class="addpg">
            <el-button class="pgbtn btnsinfo" @click="addxingcheng">新增行程</el-button>
        </div>
        <div class="addpgstyle">添加乘客方式</div>

        <Commuter type="2" ref="commuterRef" />
        <div class="remark">备注</div>
        <div class="remarkinfo">
            <el-input type="textarea" v-model="textareaValue" maxlength="500" show-word-limit resize="none" :rows="5"
                class="textarea-box" placeholder="请输入备注信息"></el-input>
        </div>
        <div class="addpg">
            <el-button class="orderbtn" @click="handleSubmit">提交订单</el-button>
        </div>
        <div class="shuoming">
            <div>说明</div>
            <div>
                备注出票要求，若满足则出票，不满足则进入特修孜订单西时关注训单状念和公众号得
            </div>
            <div>
                关于行程：(可出5月1日-5月3日)、(车次备选Z161、T146、G508)、(可出始发站重庆)。(可出终点站上海)、(一等二等均可)
            </div>
            <div>
                关于席位：靠窗、靠过道，优先DF(若无则A8C均可)、中下、同车、一个包、张三商务座、李四靠密
            </div>
        </div>

        <div class="copyright">工业和信息化部备案管理系统网站 豫B1-20080191-28</div>
    </div>
</template>

<script>
import ElImage from "element-ui/packages/image/src/main";
import ElButton from "element-ui/packages/button/src/button";
import Commuter from "./Commuter.vue";
import { addjipiaoinfo } from "@/api";
import Cookie from 'js-cookie';
export default {
    components: {
        ElButton,
        Commuter,
        ElImage,
    },
    data() {
        return {
            pgname: "",
            pgshow: false,
            dialogbgsryVisible: false,
            dialogcypgVisible: false,
            dialoggatxzVisible: false,
            dialogsfzVisible: false,
            logLoading: false,
            dialogVisible: false,
            textareaValue: "",
            pginfo: "",
            form: {
                date1: "",
                sendcity: "",
                arrivalcity: "",
                cardimg: "",
            },
            xcForm: {
                xcList: [
                    {
                        sendcity: "",
                        arrivalcity: "",
                        senddate: "",
                        flightnumber: "",
                        cabintype: "",
                    },
                ],
            },
            ruleForm: {
                pgList: [
                    {
                        rytype: "",
                        user: "",
                        idtype: "",
                        id: "",
                        zuoxi: "",
                        isbaoxian: "",
                    },
                ],
            },
            rules: {
                mobilephone: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确", trigger: "blur" },
                ],

                pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
            }
        };
    },
    created() {
        this.pgshow = true;
    },
    activated() {
        this.xcForm.xcList = [
            {
                sendcity: "",
                arrivalcity: "",
                senddate: "",
                flightnumber: "",
                cabintype: "",
            }
        ];
    },
    methods: {
        //添加行程
        addxingcheng() {
            const obj = {
                sendate: "",
                sendcity: "",
                arrivalcity: "",
                flightno: "",
                cabin: "",
            };
            this.xcForm.xcList.push(obj);
        },
        //删除行程
        removexc(idx) {
            if (idx === 0) {
                this.$message.warning("至少有1段行程信息！");
                return;
            } else {
                this.xcForm.xcList.splice(idx, 1);
            }
        },
        cabintypeChange(){
            this.$refs.commuterRef.seatTypeName=this.xcForm.xcList[0].cabintype
            console.log(this.xcForm.xcList[0].cabintype)
        },
        validateSfz(value) {
            if (!value || value.length !== 18) {
                // this.$message.error('请输入正确的身份证号');
                return false
            }
            return true
        },
        async handleSubmit() {
            const passengersList = this.$refs.commuterRef.ruleForm.passengersList;
            const userinfo = JSON.parse(Cookie.get('userinfo'));
            const params = {
                user_id:userinfo.id,
                danwei_name:userinfo.gs_name,
                pg_xingcheng: [],
                pg_name: [],
                remark_airtk: this.textareaValue,
            };
            try {
                const idList = {}
                passengersList.forEach(item => {
                    const rytypestr = item.rytype;
                    const xingmingstr = item.user;
                    if ("" == rytypestr || null == rytypestr) {
                        throw new Error("请选择人员类型");
                    }
                    if ("" == xingmingstr || null == xingmingstr) {
                        throw new Error("请输入姓名");
                    }
                    if (!item.id || (!this.validateSfz(item.id) && item.idtype === '1')) {
                        throw new Error("请输入身份证号");
                    }
                    if (!item.idtype) {
                        throw new Error("请选择证件类型");
                    }
                    if(!item.zuoxi){
                        throw new Error("请输入席座");
                    }
                    if (idList[item.id + '_' + item.idtype]) {
                        throw new Error(`身份证${item.id}重复`);
                    }
                    params.pg_name.push({
                        xinming:xingmingstr,
                        card_id:item.id,
                        renyuan_leixing:rytypestr,
                        card_type:item.idtype,
                        cabin_type:item.zuoxi,
                        is_buy_baoxian:item.isbaoxian ? '1' : '2',
                    })
                })

                this.xcForm.xcList.forEach(item => {
                    params.pg_xingcheng.push({
                         send_city:item.sendcity,
                         arrival_city:item.arrivalcity,
                         flight_number:item.flightNumber,
                         cabin_type:item.cabintype,
                         send_date:item.senddate,
                    })
                })

                const data = await addjipiaoinfo(params)
                if (data && data.data && data.data.code == 1) {
                    this.$message.success(data.data.message)
                    this.$router.push({
                        path: '/airticketorder'
                    })
                }
                else {
                    this.$message.error('提交失败')
                }
            } catch (error) {
                this.$message.error(error.message);
                return;
            }
        },
        //本公司人员
        //转向预定申请
        forwardyuding() {
            this.$router.push("/trainyudingshenqing");
        },
    },
    name: "trainyudingshenqing-right",
};
</script>

<style scoped>
.smargintop {
    margin-top: 15px;
}

.mtopsinfo {
    margin-top: 10px;
    height: 1px;
}

.pgcardid {
    color: #999999;
    margin-left: 34px;
}

.editimgs {
    width: 19px;
    height: 17px;
    margin-left: 10px;
}

.pgopertop {
    align-items: center;
    height: 60px;
}

.pgkuang {
    width: 47px;
    height: 22px;
    line-height: 22px;
    border: 1px solid #b6b6b6;
    text-align: center;
    margin-left: 20px;
    color: #999999;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    vertical-align: middle;
}

.sfz {
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    margin-left: 10px;
}

.pgck {
    color: #000000;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 18px;

    padding-left: 10px;
}

.pgleftinfo {
    margin-left: 25px;
}

.pgmleft {}

.pgmargintop {
    margin-top: 20px;
}

.btnstylesinfo {
    margin-top: 30px;
    justify-content: center;
    display: flex;
    text-align: center;
}

.cbtns {
    padding-left: 80px;
    padding-right: 80px;
    margin-left: -25px;
}

.confirmbtns {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    justify-content: center;
}

.confirmbtn {
    width: calc(48% - 0px - 10px) !important;
    padding-left: 100px;
    padding-right: 100px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.pgbtns {
    margin-top: 20px;
    width: calc(100% - 0px - 0px) !important;
    justify-content: center;
    text-align: center;
}

.pgtop {
    margin-top: 10px;
}

.pgleft {
    margin-left: 10px;
}

.centered-label .el-form-item__label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.clearbtn {
    background-color: rgba(150, 150, 150, 1);
    color: #ffffff;
}

.zjinputstyle {
    width: 10vw;
}

.btnsinfo {
    background-color: rgba(255, 255, 255, 1);
}

.remarkinfo {
}

.weiziiconst {
    width: 24px;
    height: 24px;
}

.cdiv {}

.shuoming {
    font-size: 13px;
    color: #444444;
    line-height: 25px;
}

.textarea-box {
    width: calc(100% - 0px - 0px) !important;
    background-color: #f1f1f1;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.textarea-box /deep/ .el-textarea__inner {
    background-color: #f1f1f1;
}

.remark {
    font-weight: bolder;
}

.orderbtn {
    width: 350px;
    height: 45px;
    border: 1px solid #42b134;
    color: #ffffff;
    padding: 10px;
    margin-top: 5vh;
    background-color: #42b134;
}

.pgbtn {
    width: 350px;
    height: 45px;
    border: 1px solid #000000;
    color: #000000;
    padding: 10px;
    margin-top: 5vh;
}

.addpg {
    margin-left: 20vw;
    margin-right: 20vw;
}

.xuhao {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
}

.pginfotitle {
    width: calc(100% - 0px - 70px) !important;
    font-weight: bolder;
    margin-left: 1vw;
}

.pginfo {
    margin-top: 2vh;
}

.pgdel {
    width: 70px;
}

.sfsbbtn {
    border: 1px solid #000000;
    color: #000000;
    margin-left: 1vw;
}

.btnoper {}

.bluecolor {
    color: #42b134;
}

.startweight {
    font-weight: bolder;
    font-size: 20px;
}

.iconstyles {}

.addpgstyle {
    font-weight: bolder;
    margin-top: 2vh;
}

.copyright {
    font-size: 12px;
    text-align: center;
    margin-top: 5vh;
    color: #b6b6b6;
}

.mleftmargin {
    margin-left: 1vw;
}

.pager {
    margin-top: 5vh;
    margin-left: 10vw;
}

.shouye {}

.sybtn {
    width: 100px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.btnnodiv {
    margin-left: 1vw;
}

.btnno {
    width: 50px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.commonmtops {
    margin-top: 15px;
}

.commonmtop {
    margin-top: 20px;
}

.btnmargin {
    margin-top: 1vh;
}

.btnstyle {
    width: 100px;
    height: 35px;
    background-color: #42b134;
    color: #ffffff;
    border-radius: 5px;
}

.operbtn {
    margin-left: 8vw;
}

.checizuowei {
    margin-left: 5vw;
}

.colorstyle {
    color: #f49200;
    font-weight: bolder;
}

.checileixing {
    margin-left: 5vw;
}

.checimoney {
    margin-left: 5vw;
}

.zwleixing {
    margin-top: 0.1vh;
}

.cmtopzw {
    margin-top: 2vh;
}

.cxinfo {}

.endcheci {
    margin-left: 3vw;
}

.sleft {}

.scheci {
    margin-left: 2vw;
}

.startzhan {
    margin-left: 0.5vw;
}

.scdiv {
    margin-top: 10px;
    margin-left: 1vw;
}

.sccdate {
    font-size: 20px;
    font-weight: bolder;
}

.haoshi {
    margin-left: 3vw;
    width: 462px;
    justify-content: center;
    justify-items: center;
    text-align: center;
}

.tcontentxc {
    background-color: #f1f1f1;
    height: 100px;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
}

.tcontent {
    background-color: #f1f1f1;
    height: 20vh;
    margin-top: 2vh;
    padding-top: 4vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.mleftd {
    margin-left: -2vw;
}

.querybtn {
    background-color: #42b134;
    color: #ffffff;
    border-radius: 5px;
    margin-left: -1vw;
}

.formstyleyd {
    padding-top: 2.2vh;
}

.container {}

.header {
    font-size: 12px;
}

.flex {
    display: flex;
}

.weiziicon {}

.pleft {
    margin-left: 0.5vw;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.hcxc {
    background-color: #f1f1f1;
    height: 150px;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.formstyleyd .inputstyle {
    width: 207px;
}

.hc {
    background-color: #f1f1f1;
    height: 100px;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    align-items: center;
    display: flex;
}

.hcpg {
    background-color: #f1f1f1;
    height: 200px;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.pgdivinfo {
    height: 93px;
    background: #f1f1f1;
    border-radius: 10px;
    width: 280px;
}

.spanstyle {
    margin-top: 50px;
    display: inline-block;
}
</style>
